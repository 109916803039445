import { useState, useEffect } from "react";
const moment = require('moment');
import {
  Badge,
  Button,
  Card,
  Table,
  Container,
  CardHeader,
  Row,
  Col,
  Modal,
  ModalBody 
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import { taskmanageService } from "../../../services/taskmanageService";
import { usermanageService } from "../../../services/usermanageService";
import "../../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import Select from 'react-select';

const AgeingReport = (props) => {

  const user_info = JSON.parse(localStorage.getItem("user-info"));
  const [uniqueuserslist, setUniqueUsersData] = useState([]);
  const [uniqueqalist, setUniqueQAData] = useState([]);
  const [reportdata, setReportdata] = useState([]);
  const [clientdetails,setclientdetails] = useState({});
  const [clientdetailPopup,setclientdetailPopup] = useState(false);
  const [statuslist, setStatusdata] = useState([]);
  const initialData = {
    user_id : '',
    tl_id : '',
    status : '',
    type : user_info.role_id == 10 ? 'phases' : 'tasks'
  }
  const [inputFields, setInputfields] = useState(initialData);
  useEffect(() => {
    get_report_data();
    get_unique_userslist()
    get_qatls()
    get_status_list()
  },[]);

  function get_report_data(data = inputFields){
   taskmanageService.ageingreport(data).then((response) => {
    setReportdata(response.data)
  });
  }
  function get_unique_userslist(){
    usermanageService.getclient().then((response)=>{
      setUniqueUsersData(response.data.clients);
    });
  }

  function get_qatls(type = inputFields.type){
    usermanageService.getqatls(type).then((response)=>{
      setUniqueQAData(response.data.data);
     
    });
  }
  function get_status_list() {
    taskmanageService.getstatus().then((response) => {
      setStatusdata(response.data.status);
    });
  }

  function inputhandler1(e,field) {
    setInputfields((prevInputFields) => ({
      ...prevInputFields,
      [field]: e.target.value,
    }));
  }

function inputhandler(field,value) {
  const data = {
    [field]: value,
    user_id : '',
    tl_id : '',
    status : '',
  }
  setInputfields((prevInputFields) => ({
    ...prevInputFields,
    [field]: value,
    user_id : '',
    tl_id : '',
    status : '',
  }));
  get_qatls(value)
  get_report_data(data);
}

const handleClick = (status, ageing) => {
    taskmanageService.ageingdetails(status, ageing,inputFields).then((response) => {
      setclientdetails(response.data.data);
      setclientdetailPopup(!clientdetailPopup);
    });
};

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <div className="row">
          <div className="col-xl-12">
            <div className="col-md-12 col-12"></div>
            <Row>
              <Col xl="12">
              <Card>  
              <CardHeader className="border-0">
                {(user_info.role_id == 14) ? 
                <>
                <Row>
                  <Button className={(inputFields.type == 'tasks') ? "col-xl-2 btn btn-primary"  : "col-xl-2 btn"}   onClick={(e) => inputhandler('type','tasks')}>QA Team</Button>
                  <Button className={(inputFields.type == 'phases') ? "col-xl-2 btn btn-primary"  : "col-xl-2 btn"}  onClick={(e) => inputhandler('type','phases')}>Support Team</Button>
                  </Row>
                <br></br>
                </>
                :  null }
              <Row className="filter" >
               {/* <div className="filter" style={{width:'100%'}}> */}
                   <div className="col-xl-3 col-md-3 col-xs-12">
                    <h3 className="clientformtag"> Select Client</h3>
                   {(uniqueuserslist.length > 0) ? 
                   
                    <select 
                    className="form-control" 
                    name="user_id"
                    onChange={(e) => inputhandler1(e,'user_id')}
                    value={inputFields.tl_id} // control the selected value
                  >
                     <option value="0" key="0" >Select Client</option>
                    {uniqueuserslist.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                 : null }
                  </div>
               
                   <div className="col-xl-3 col-md-3 col-xs-12">
                      <h3 className="clientformtag"> Select TL</h3>
                      {(uniqueqalist.length > 0) ? 
                        <select 
                          className="form-control" 
                          name="tl_id"
                          onChange={(e) => inputhandler1(e,'tl_id')}
                          value={inputFields.tl_id} // control the selected value
                        >
                            <option value="0" key="0" >Select TL</option>
                          {uniqueqalist.map(option => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      : null}
                   </div>
                   <div className="col-xl-3 col-md-3 col-xs-12">
                      <h3 className="clientformtag"> Select Status</h3>
                      {(statuslist.length > 0) ? 
                   <select
                   value={inputFields.status}
                   className="form-control"
                   onChange={(e) => inputhandler1(e,'status')}
                   >
                     <option value="0" key="0" >Select Status</option>
                     {statuslist &&
                      statuslist.length &&
                      statuslist != null ? (
                      statuslist.map((status) =>
                          <option key={status.id} value={status.id} >
                             {status.name}
                          </option>
                       )
                     ) : (
                       <option>No data Found</option>
                     )}
                   </select>
                 : null }
                   </div>
                    <div className="col-xl-3 col-md-3 col-xs-12">
                    <br></br>
                      <Button className="col-xl-6 btn btn-primary"  onClick={(e) => get_report_data()}>Search</Button>
                    </div>
                    {/* </div> */}
                </Row>
                <br></br>
                <Row className="filter" style={{ padding: "0 25px" }}>
                  
                  <div className="d-flex">
                  <h3 className="mb-0 mt-2" style={{ fontSize: "20px" }}>
                    Ageing Report
                  </h3>
                  {/* <div><Button
                      className="btn-icon-clipboard  ml-5"
                      type="button"
                      onClick={downloadReport}
                      
                      title="Download Report"
                    >
                    <i className="fas fa-download" style={{fontSize:'14px'}}></i> Export
                  </Button></div> */}
                
                  </div>
                
                </Row>
              </CardHeader>
                   
                    <Table className="align-items-center table-flush custom-style" responsive>
                      <thead className="thead-light">
                          <tr>
                          <th>Status</th>
                          {reportdata.uniqueAgeing &&
                    reportdata.uniqueAgeing.length &&
                    reportdata.uniqueAgeing != null ? (
                      reportdata.uniqueAgeing.map((item, index) => (
                          <th>{item}</th>
                      ))
                      ):null }
                          </tr>
                      </thead>
                      <tbody>
                          {reportdata.bucketedData &&
                          Object.keys(reportdata.bucketedData).length &&
                          reportdata.bucketedData != null ? (
                            Object.keys(reportdata.bucketedData).map((status, index) => (
                              <tr
                                key={index}
                                className={((index % 2 == 0)? 'blue-row':(index % 3 == 0)?'purple-row':'orange-row') }
                              >
                                <td>{status}</td>
                                {reportdata.uniqueAgeing &&
                                reportdata.uniqueAgeing.length &&
                                reportdata.uniqueAgeing != null ? (
                                  reportdata.uniqueAgeing.map((age, ageIndex) => (
                                    <td 
                                      key={ageIndex} 
                                      onClick={(e) => handleClick(status, age)}
                                      style={
                                        reportdata.bucketedData[status][age] !== undefined 
                                          ? { background: '#ffc873', fontWeight: 'bold' } 
                                          : {}
                                      }
                                    >
                                        {reportdata.bucketedData[status][age] || 0}
                                    </td>
                                  ))
                                ) : null}
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td >No records found</td>
                            </tr>
                          )}
                      </tbody>

                     </Table>
                   </Card> 
                  
              </Col>
             </Row>
             <Row>
             <Modal
            size="lg"
            className="taskdetailmodal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            toggle={() => setclientdetailPopup(!clientdetailPopup)}
            isOpen={clientdetailPopup}
          >
            <div className="modal-header">
            <h3>Client Details</h3>
              <button
                aria-label="Close"
                className=" close"
                type="button"
                onClick={() => setclientdetailPopup(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody style={{paddingTop:'0px'}}>
          
              <Table>
                <thead>
                  <tr>
                    <th>Client Name</th>
                    <th className="whitespace">Sub Client Name</th>
                    {inputFields.type == 'phases' ? 
                    <th className="whitespace">Phase Name</th>
                    : null }
                    <th className="whitespace">Status</th>
                    <th className="whitespace">Book Keeping software</th>
                    <th className="whitespace">Acc. prod. software</th>
                    <th className="whitespace">Type</th>
                    <th className="whitespace"> TL</th>
                    <th className="whitespace"> TM</th>
                    <th className="whitespace">Budgeted Hours</th>
                    <th className="whitespace">Year end Date</th>
                    <th className="whitespace">Job Received Date</th>
                    <th className="whitespace">Due date</th>
                    <th className="whitespace">Job Completion Date</th> 
                    
                  </tr>
                </thead>
                <tbody>
                {clientdetails && clientdetails.length
                          ?                               
                          clientdetails.map((client,d) => (
                              <tr key={d} className={((d % 2 == 0)? 'purple-row': 'orange-row' )}>
                                <td>{client.name}</td>
                                <td  className="whitespace">{client.sub_client}</td>
                                {inputFields.type == 'phases' ? 
                                <td  className="whitespace">{client.phase_name}</td>
                                : null }
                                <td  className="whitespace">{client.status_name}</td>
                                <td  className="whitespace">{client.book_keeping_software}</td>
                                <td  className="whitespace">{client.account_prod_software}</td>
                                <td  className="whitespace">{client.type}</td>
                                <td  className="whitespace">{client.lead}</td>
                                <td  className="whitespace">{client.member}</td>
                                <td  className="whitespace">{client.budget}</td>
                                <td  className="whitespace">{client.year_end_date}</td>
                                <td  className="whitespace">{client.raised_date}</td>
                                <td  className="whitespace">{client.due_date}</td>
                                <td  className="whitespace">{client.completed_date}</td>
                                
                              </tr>
                            ))
                          :
                           ( <p>No Data Found</p> )}
                </tbody>
              </Table>
            </ModalBody>
           </Modal>
           </Row>
            </div>
        </div>
      </Container>
    </>
  );
};

export default AgeingReport;
