import { Link,useLocation } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";
import {userService} from '../../services/authentication';
import {manageService} from '../../services/manageaccountservices';
import { useEffect,useState } from 'react';
import { useHistory } from "react-router-dom";
import 'assets/css/custom-css.css';
import 'assets/css/themecustom-style.css';
import Clock from 'react-live-clock';
import Notificationcomponent from 'components/Notificationcomponent.js';
import { Dashboard } from "../../views/analyst/Dashboard.js";
import { connect } from "react-redux";
import Announcementcomponent from 'components/Announcementcomponent.js';
import {taskmanageService} from 'services/taskmanageService.js';
const AdminNavbar = (props) => {

  const [notifymodel,setNotifymodel] = useState(false);
  const user_info = JSON.parse(localStorage.getItem("user-info"));
  const location = useLocation();
  function signout(){
    userService.logout().then((response)=>{
       if(response.status == 200){
          // history.push('/');
          // window.location.href = '/';
       }
    }); 
  }

  function opennotification(){
    setNotifymodel(true); 
      // return ( <Notificationcomponent/> ); 
  }
  const styles = {
    'color':'#4a4444',
  };
  useEffect(() => {
    getAnnouncement();
  },[]);


  const [announcement,setannouncement] = useState([]);
  function getAnnouncement(){
     
     taskmanageService.getactiveAnnouncement().then((response) => {
 
        setannouncement(response.data.data);

     });
  }
  return (
    <>
    {announcement.length > 0 && user_info.role_id !=13 ? 
    <Announcementcomponent parameter={announcement}></Announcementcomponent>
    :''}
      <Navbar className="navbar-top navbar-dark navbar-custom-style" style={(announcement.length > 0 && user_info.role_id !=13) ? { marginTop: '50px' } : {}} expand="md" id="navbar-main">
      
        <Container fluid>
          {/* <Link
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            to="/admin"
          > */}
          
          
           <div className="h4 mb-0 " >
            <div className="clock-style">
                <h1 >Welcome Back {user_info.name}</h1>
                <Notificationcomponent/>
            </div>
          </div>  
          {/* </Link> */}
          {(user_info.rolename != 'admin' && user_info.role_id != 13  && location.pathname=='/admin/index')?
          <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
            <FormGroup className="mb-0">
              <InputGroup className="input-group-alternative searchbox-container">
                <Input placeholder="Search company..." id="companydata" className="search-box" style={styles} type="text"  onKeyDown={props.searchcompany}/>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </Form>
          : null }
          {(user_info.role_id == 13  && location.pathname=='/admin/index')?
          <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
            <FormGroup className="mb-0">
              <InputGroup className="input-group-alternative searchbox-container">
                <Input placeholder="Search sub client..." id="subclientname" className="search-box" style={styles} type="text"  onKeyDown={props.searchsubclient}/>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </Form>
          : null }
          {/* <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                   
                      <i class="ni ni-single-02 text-yellow"></i>
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                         name
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>My profile</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={signout}>
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav> */}
        </Container>
      </Navbar>
    </>
  );
};

const mapStateToProps = (state) => ({
  name: state.companysearch.name,
  subclientname: state.subclientsearch.subclientname,
});
const mapDispatchToProps = (dispatch) => {
  return {
    searchcompany: (e) => {
     if(e.target.value != ''){
          if(e.key === 'Enter'){
            e.preventDefault();
            var company_name = document.getElementById('companydata');
            if(company_name.value.length <= 1){
              var company_name = document.getElementById('companydatas');
            }
            dispatch({
              type: "search",
              payload: company_name.value
            })
        }
     }else{
      var company_name = document.getElementById('companydata');
      if(company_name.value.length <= 1){
        var company_name = document.getElementById('companydatas');
      }
      dispatch({
        type: "search",
        payload: company_name.value
      })
     }   
    },
    searchsubclient: (e)=> {
      if(e.target.value != ''){
           if(e.key === 'Enter'){
             e.preventDefault();
             var subclientname = document.getElementById('subclientname');
             dispatch({
               type: "client_search",
               payload: subclientname.value
             })
         }
      }else{
       var subclientname = document.getElementById('subclientname');
       
       dispatch({
         type: "client_search",
         payload: subclientname.value
       })
      }   
     },
    
  };
};
export default connect(mapStateToProps,mapDispatchToProps)(AdminNavbar);
