import { useState, useEffect,useRef } from "react";
import React from 'react';
import ReactDatetime from "react-datetime";
import { connect } from "react-redux";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Pagination,
  PaginationItem,
  PaginationLink,
  PopoverBody,
  UncontrolledPopover ,
  InputGroupAddon,
  InputGroupText,
  Label
  

} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import { taskmanageService } from "../../services/taskmanageService.js";
import { clientmanageService } from "../../services/clientmanageService.js";
import 'assets/css/themecustom-style.css';
import { ToastContainer, toast } from 'react-toastify';
import { domainUrl} from '../../constant';
import '@flowjs/flow.js';
import Flow from "@flowjs/flow.js";
import { async } from "@firebase/util";
import { useHistory,useParams } from "react-router-dom";
import { Dropzone, FileItem, FullScreenPreview } from "@dropzone-ui/react";
import { manageService } from "../../services/manageaccountservices";
const axios = require("axios");
import { apiUrl } from "../../constant";
import { authtoken } from "global.js";
import {Formloader} from 'components/Loader/PageLoader.js';
import Select from 'react-select';

const Client = (props) => {
  const params  = useParams();
  const user_info = JSON.parse(localStorage.getItem("user-info"));
  const [currentPage, setCurrentPage] = useState(null);
  const [formloader,setformloader] = useState(false);
  const [last_page, setLastPageData] = useState(0);
  const [clientdata, setClientdata] = useState([]);
  const [dashboardStatistics, setdashboardStatistics] = useState([]);
  const [filterFields,setfilterFields] = useState([]);
  const [statuslist, setStatusdata] = useState([]);
  const dstatus = (user_info.role_id == 13) ? 'assigned' : '';
  const [filtertype,setfiltertype] = useState(dstatus);
  const [showfilterbox,setshowfilterbox] = useState(false);
  const [filterlist, setfilterlist] = useState([]);
  const [filterApplyFields,setfilterApplyFields] = useState([]);
  const [amendmentModalOpen, setAmendmentModal] = useState(false);
  const [state, setState] = useState({startDate:'',endDate:''});
  const [statesend, setStatesend] = useState({startDate:'',endDate:''});
  

  let history = useHistory();
  const initial_amend = {
    am_company: '',
    am_company_name: '',
    am_analyst: '',
    select_due_date: '',
    am_budget: '',
  };
  const [amendmentdata, setAmendmentdata] = useState(initial_amend);
  let initialStateData = {
    file : ""
  };
  const [inputFields, setInputfields] = useState(initialStateData);
  function get_status_list() {
    taskmanageService.getclientstatus().then((response) => {
      setStatusdata(response.data.status);
    });
  }
  const handlePageClick = (e, index) => {
    e.preventDefault();
    get_list(index,null,filterFields,filtertype,statesend.startDate, statesend.endDate);
  };
  function amendmentModal() {
    getcompanies();
    setAmendmentModal(!amendmentModalOpen);
    // console.log(companieslist);
  }
  const [companylist, setCompanies] = useState([]);
  const [opensecondpopup, setsecondpopup] = useState(false);
  function getcompanies(){
    clientmanageService.getsubclientlistsearchable(user_info.id).then((response)=>{
       setCompanies(response.data);
    });
  }

  const handlePreviousClick = () => {
    const prev = currentPage - 1;
    get_list(prev,null,filterFields,filtertype,statesend.startDate, statesend.endDate);
  };

  const handleNextClick = () => {
    const next = currentPage + 1;
    get_list(next,null,filterFields,filtertype,statesend.startDate, statesend.endDate);
  };

  const get_list = async (pageNumber = 1,e,filterFields,filtertype='',start_date='',end_date='') => { 
    let textbox = props.subclientname;
    // let  textbox = '';
    // if(e != null){
    //    textbox = e.target.value;
    // }
    const cloneValues = {...filterFields};
    let status ='';
    if(params.status!='undefined' && params.status!=undefined){
      status = params.status;
      filtertype = status;
    }
    let user_id ='';
    if(params.user_id!='undefined' && params.user_id!=undefined){
      user_id = params.user_id;
      cloneValues[9] = {
        ...cloneValues[9],
        ['field']: 'user_id',
        ['value']: user_id,
        ['operator']: '='
      };
    }
   
    setfilterFields(cloneValues);
    taskmanageService.getclientTasks(pageNumber,textbox,filterFields,filtertype,sortfield,sortorder,start_date,end_date).then((response) => {console.log(response.data.data.data)
      setClientdata(response.data.data.data);
      setCurrentPage(response.data.data.current_page);
      setLastPageData(response.data.data.last_page);
    });
  };
  function apply_amendment() {
    // console.log(amendmentdata);
    console.log(amendmentdata);
    // return;
    clientmanageService.create_amendment(amendmentdata).then((response) => {
      if (response.status == 200) {
        toast.success(response.data.message);
        setAmendmentModal(false);
      } else {
        toast.error(response.data.message);
      }
    });
  }
  function amenmentdatadata(e,cm='') {
    if(e!=null){
    if(cm!=''){
      setAmendmentdata({ ...amendmentdata, [cm]: e.value , ['am_company_name'] : e.label });
      setsecondpopup(true);
    }else{
      if (e.target.value != "") {
        setAmendmentdata({ ...amendmentdata, [e.target.name]: e.target.value });
        setsecondpopup(true);
      } else {
        setsecondpopup(false);
      }
    }
  }
  }
  const formatDate = (dateStr) => {
    if(dateStr!='' && dateStr!= null){
    const [year, month, day] = dateStr.split('-');
    let newDate = `${day}-${month}-${year}`;
    return newDate;
    }else{
      return null;
    }
  };

  const upload_doc_popup = () => {
     history.push("/admin/uploaddoc");
    // setdocuemntPopup(!documentPopupstate); 
  }

  const editjob_nav = (task_id) => {
    history.push("/admin/editjob/"+task_id);
  }

  const inputRef = useRef(null);
  function onRespondClick(){
    setformloader(true);
    const formData = new FormData();
    formData.append(
      "file",
      new Blob([inputFields.file], { type: inputFields.file.type }),
      inputFields.file.name || "file_1"
    );
    axios({
      method: "post",
      url: `${apiUrl}/importtasks`,
      data: formData,
      headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "multipart/form-data" },
    })
      .then(
        function (response) {
          setInputfields(initialStateData)
          if (response.status == 200) {
            toast.success(response.data.message);
          }
          if(response.status = 201){
            toast.error(response.data.message);
          }
          inputRef.current.value = null;
          setformloader(false);
        },
        (error) => {
           inputRef.current.value = null;
          setformloader(false);
          return error.response;
        }
      )
      .catch(function (error) {});
  }
  function onFileChange(e){
    setInputfields({ ...inputFields, [e.target.name]: e.target.files[0] });
  }

  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate() + 1).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
};

function get_dashboard_data(start_date=statesend.startDate,end_date=statesend.endDate,client='') {
  taskmanageService.getClientDashboardData(start_date,end_date,client).then((response) => {
    setdashboardStatistics(response.data.data);
  });
}
const commentData = {
  task_id: "",
  comment: "",
  is_client : 1
};
const [inputComments, setinputComments] = useState(commentData);
const [commentModalOpen, setCommentModalOpen] = useState(false);
const [commentlists, setCommentLists] = useState([]);
function addcommentform(id,index){
  setinputComments({task_id:id});
  setCommentModalOpen(!commentModalOpen);
  get_comment_lists(id);
  let temp_state = [...clientdata];
  let temp_element = { ...temp_state[index] };
  temp_element.comment_count = 0;
  temp_state[index] = temp_element;
  setClientdata(temp_state);
}

function get_comment_lists(task_id){
  taskmanageService.getClientcommentlists(task_id).then((response) => {
    setCommentLists(response.data.data);
  });
};

const handlemaineditRow = (index) => {
    let temp_state = [...clientdata];
    let temp_element = { ...temp_state[index] };
    temp_element.mainediting = true;
    temp_state[index] = temp_element;
    setClientdata(temp_state);
}
const editField = (value, field, id, type, index,e='') => {
    let temp_stt = [...clientdata];
    let temp_elet = { ...temp_stt[index] };
    if(field == 'client_status' && value == "2" && (temp_elet['remarks'] == '' || !temp_elet['remarks'])){
      toast.error('Please fill remarks for on hold case');
    }else{
    taskmanageService.editRow(value, field, id, type).then((response) => {
      let temp_st = [...clientdata];
      let temp_ele = { ...temp_st[index] };
    
      if(response.status == 200){
        if (field == "client_status") {
            Object.entries(statuslist).filter(([k, v]) => v.id == value).reduce((acc, [k, v]) => {
              temp_ele['status_name'] = v.name;
              temp_ele['status_color_code'] = v.color_code;
          }, {});
            temp_ele['status_id'] = value;
            if(value==3){
              let newDate = new Date()
              let months = newDate.getMonth() + 1;
              let month = (months<10)?'0'+months:months;
              temp_ele['com_date'] = newDate.getDate()+'-'+month+'-'+newDate.getFullYear();
            }
            if(value != 2){
              temp_ele['remarks'] = '';
            }
          }
          toast.success(response.data.message);
      }else{
          toast.error(response.data.message);
      }
      temp_ele.mainediting = false;
      temp_st[index] = temp_ele;
      if (field != "client_status") {
        temp_ele[field] = value;
      }
      
      setClientdata(temp_st);
      get_dashboard_data();
    });
  }
};
function onFileChange1(e){
  const files = Array.from(e.target.files);
  console.log(e.target.files)
  setInputfields({ ...inputFields, [e.target.name]: files });
  console.log(inputFields)
}
const [taskdetails,settaskdetails] = useState({});
const [taskdetailPopup,settaskdetailPopup] = useState(false);
const [documentlists, setDocumentLists] = useState([]);
const [rejectionlists, setRejectionLists] = useState([]);
const [documentModalOpen, setdocumentModalOpen] = useState(false);
const [rejectionModalOpen, setrejectionModalOpen] = useState(false);
const [otherInfoModalOpen, setotherInfoModalOpen] = useState(false);
const [allclient, setAllclient] = useState(true);
const [filteractive, setFilterActive] = useState(false);
const [sendmailpopup,setsendmailPopup] = useState(false);
const [sendmailpopupdetails,setsendmailPopupDetails] = useState(false);
const taskDetail = (task_id,index) => {
  taskmanageService.gettaskdetail(task_id,'','').then((response) => {
    settaskdetails(response.data.data);
    setDocumentLists(response.data.files);
    setRejectionLists(response.data.rejections);
    settaskdetailPopup(!taskdetailPopup);
  })
}
const [uniquecompanieslist, setUniqueCompaniesData] = useState([]);
const [companieslist, setCompaniesData] = useState([]);
function get_unique_companieslist(){
  manageService.get_clientusers().then((response)=>{
    setUniqueCompaniesData(response.data);
  });
  clientmanageService.getuniqueclient().then((response)=>{
    setCompaniesData(response.data);
  });
}
const companyfilter = (e,index,field) => {
  console.log(e)
  if(e.value == ''){
    setAllclient(true);
  }else{
    setAllclient(false);
    setFilterActive(true);
    
  }
  const cloneValues = {...filterFields};
  cloneValues[index] = {
    ...cloneValues[index],
    ['field']: field,
    ['value']: e.value,
    ['operator']: '='
  };
  setfilterFields(cloneValues);
  get_list(1,null,cloneValues,filtertype,statesend.startDate, statesend.endDate);
  get_dashboard_data(statesend.startDate, statesend.endDate,e.value);
}
const downloadfile = (path,name,extension,task_id) => {
  taskmanageService.download_file(path,name,extension,task_id).then((response) => {
  
  });
}
function downloadReport(){
  taskmanageService.client_task_report(filterFields,filtertype).then((response) => {
  
  });
}
const downloadAllfile = (userid,taskid) => {
  taskmanageService.download_all_file(userid,taskid).then((response) => {
  
  });
}
const report_details = (id,role_id,status) => {
  if(statesend.startDate!=null && statesend.endDate!=null){
    var drange = "/"+statesend.startDate+"/"+statesend.endDate
  }else{
    var drange = "";
  }
  history.push({
    pathname: "client_details/"+id+"/"+status+drange,
  });
}
const downloadformatfile = () => {
  return axios({
    method: "get",
    responseType: 'blob', 
    url: `${apiUrl}/download_file?path=importformat.xlsx`,
    headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "application/vnd.ms-excel" },
  })
  .then(function (response) {
    const outputFilename = 'importformat.xlsx';
    const url = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });  
}

function filterallcom({type}){
  setfiltertype(type)
  // setmainfilter(type);
  get_list(1,null,filterFields,type,statesend.startDate, statesend.endDate);
}
const getFilterList = () => {
  clientmanageService.getfilterlist().then((response) => {
    setfilterlist(response.data.filter);
    setshowfilterbox(!showfilterbox);
  })
}
function filterInputHandler(e,index,op_name='') {
  const cloneValues = {...filterFields};
    if(op_name!=''){
      cloneValues[index] = {
        ...cloneValues[index],
        ['op_name']: op_name,
      };
    }
    var index1 = e.target.selectedIndex;
    if(index1){
      cloneValues[index] = {
        ...cloneValues[index],
        ['val_name']: e.target[index1].text,
      };
    }
    cloneValues[index] = {
      ...cloneValues[index],
      [e.target.name]: e.target.value,
    };

    console.log('filter data');
    console.log(cloneValues);
    setfilterFields(cloneValues);
  
  }
  const applyFilter = (e='') => {
    get_list(1,null,filterFields,filtertype,statesend.startDate, statesend.endDate);
    if(e==''){
      setfilterApplyFields(filterFields);
      document.getElementById('tooltipfilter').click();
    }
  }
  const removefromFilter = (index) => {
    let state = {...filterFields};
    Object.entries(state) // converts each entry to [key, value]
      .filter(([k, v]) => v.field == index) // define the criteria to include/exclude items
      .reduce((acc, [k, v]) => {
         delete state[k];
         setfilterFields(state);
         setfilterApplyFields(state);
      }, {});
  }
function filterClickHandler(name,value,index) {
  const cloneValues = {...filterFields};
  cloneValues[index] = {
    ...cloneValues[index],
    [name]: value,
  };
  setfilterFields(cloneValues);
  // console.log(filterFields)
}

// sorting functon
const [sortfield,setsortfield] = useState('');
const [sortorder,setsortorder] = useState('asc');
function sortTable(field){
  console.log(field)
  setsortfield(field);
  if(sortorder=='asc'){
    setsortorder('desc');
  }else{
    setsortorder('asc');
  }
}

function search_all_company(){
  let textbox = props.subclientname;
  let pageNumber = 1;
  taskmanageService.getclientTasks(pageNumber,textbox,filterFields,filtertype,sortfield,sortorder).then((response) => {
    setClientdata(response.data.data.data);
      setCurrentPage(response.data.data.current_page);
      setLastPageData(response.data.data.last_page);
  });
}


useEffect(() => {
  get_status_list();
  get_dashboard_data();
  get_unique_companieslist();
  search_all_company();
}, []);
useEffect(() => {
  get_list();
}, [props.subclientname]);
  // date range
  function setEndDateRange(e){
    var month = e._d.getMonth() + 1;
    var date = e._d.getDate();
    var year = e._d.getFullYear();
    var dd = date + '-' + month + '-' + year;
    setStatesend({ ...statesend,endDate: dd });
    setState({ ...state,endDate: e });
    

    get_list(1,null,filterFields,filtertype,statesend.startDate, dd);
  }
  
  function setStartDateRange(e){
    var month = e._d.getMonth() + 1;
    var date = e._d.getDate();
    var year = e._d.getFullYear();
    var dd = date + '-' + month + '-' + year;
    setStatesend({ ...statesend, startDate: dd })
    setState({ ...state, startDate: e })
    console.log(statesend)
    console.log(state)
  }
  const [disablestate, setdisablestate] = useState(false);
  function sendmail(flag=0){
    setdisablestate(true);
    let id = selecteduser;

    taskmanageService.clientdetailsexportmail(id,statesend.startDate, statesend.endDate,flag,sendmailbodydata.emailbody,sendmailbodydata.name,inputFields,sendmailbodydata.emailccboxData,sendmailbodydata.emailtoboxData).then((response) => {
      if(response.status==200){
      toast.success(response.data.message);
      setsendmailPopup(!sendmailpopup);
      setdisablestate(false);
      setInputfields(initialStateData);
      }
    });
  }


  function add_budget(){
    history.push("/admin/addbudget/");
  }
  const [sendmailpopupdata, setsendmailpopupdata] = useState([]);
  const [emailtoboxlength, setEmailToBoxLength] = useState(0);
  const [tobox, setToBox] = useState(0);
  const [selecteduser,setselecteduser] = useState(false);
  function getpopupdata(id,edited=0){
    taskmanageService.clientdetailsexportmail(id,statesend.startDate, statesend.endDate).then((response) => {

      if(response.status==200){
        console.log(response.data)
        setsendmailpopupdata(response.data.data)
     
        setsendmailbodydata({
          ...sendmailbodydata, 
          emailbody: response.data.data.body,
          name : response.data.data.name,
          emailtoboxData:response.data.data.tomail,
          emailccboxData:response.data.data.ccmail,
        });
        if(response.data.data.tomail.length > 0){
          setToBox(response.data.data.tomail.length)
         
        }
        if(response.data.data.ccmail.length > 0){
          setCCBox(response.data.data.ccmail.length)
          
        }
        setselecteduser(id)
      }
    });
    if(edited==0){
    setsendmailPopup(!sendmailpopup);
    }
  }
  const [sendmailbodydata, setsendmailbodydata] = useState({emailbody:'',name:'', emailtoboxData : [],emailccboxData:[]});
  function changebody(e){
    const cloneValues = {...sendmailbodydata};
    cloneValues[e.target.name] = e.target.value;
    setsendmailbodydata(cloneValues);

  }
  const customStyles = {
    cell: {
      border: '1px solid black',
      padding: '5px',
      // textAlign: 'center',
    },
    '#main_table tr': {
      padding: 0,
    },
    '#main_table tr th': {
      color: 'white',
      fontWeight: 700, // Removed 'px' from the value
      border: '1px solid black',
      backgroundColor: '#0867d2',
      padding: '5px', // Removed 'px' from the value
      textAlign: 'left', // Corrected property name
    },
    oddRow: {
      backgroundColor: '#f2f2f2',
    },
    evenRow: {
      backgroundColor: '#ffffff',
    },
  };
  const noscroll = {
    overflow: 'hidden'
  }
  const handleeditRow = (index) => {

    let temp_state = [...sendmailpopupdata.data2];
    let temp_element = { ...temp_state[index] };
    temp_element.editing = true;
    temp_state[index] = temp_element;
    setsendmailpopupdata({ ...sendmailpopupdata, data2: temp_state });
} 
const inputhandler = (e, field,index,id)=>{
  taskmanageService.editCommonTable(e.target.value,field,id,'tasks').then((response)=>{
    if(response.status == 200){
      toast.success(response.data.message);
      getpopupdata(selecteduser,1)
    //   let temp_state = [...sendmailpopupdata.data2];
    //   console.log(temp_state)
    // let temp_element = { ...temp_state[index] };
    // temp_element.editing = false;
    // temp_state[index] = temp_element;
    // temp_element[field] = e.target.value;
    // console.log(temp_state)
    // setsendmailpopupdata({ ...sendmailpopupdata, data2: temp_state });
    
    }
  });

}
const [misMailData,setMisMailData]=useState([]);
const openModal = () => {
  history.push("/admin/mismail");
  // taskmanageService.getMisMailDetails().then((response) => {
  //   setMisMailData(response.data.data);
  //   setsendmailPopupDetails(true);
  // });
}
  // end

  const [ccbox, setCCBox] = useState(0);
 
  const addCCbox = () => {
    setsendmailbodydata(prevState => ({
      ...prevState,
      emailccboxData: [...prevState.emailccboxData, ''] // Add an empty string for a new input
    }));
  };
  const removeCCbox = ()=>{
    if(ccbox > 0){
      const setdata = ccbox-1;
      setCCBox(setdata)
    }
  }

  const inputTomailhandler = (e,k)=>{
    const cloneValues = {...sendmailbodydata};
    cloneValues['emailtoboxData'][k] = e.target.value;
    setsendmailbodydata(cloneValues);
  }
  const removeEmailbox =(k)=>{
    
    const updatedEmails = [...sendmailbodydata.emailccboxData];
    updatedEmails.splice(k, 1);

    setsendmailbodydata(prevState => ({
        ...prevState,
        emailccboxData: updatedEmails,
    }));

    // Update the email box count if needed
    if (ccbox > 0) {
      const setdata = ccbox-1;
      setCCBox(setdata)
        
    }
  }
  const inputCCmailhandler = (e, k) => {
    const newEmailCCData = [...sendmailbodydata.emailccboxData];
    newEmailCCData[k] = e.target.value; // Update the specific index with the new value
    setsendmailbodydata(prevState => ({
      ...prevState,
      emailccboxData: newEmailCCData
    }));
  };
  console.log(sendmailbodydata)
  return (
    <>
      <Header />
      <Container className="mt--7"  fluid>
      {/* {user_info.role_id == 13 ? 
      <> */}
      <Row className=" row">
            <Col xl="2">
              <Button
                className="dash-button "
                color="primary"
                type="button"
                onClick={() => upload_doc_popup()}
              >
                {" "}
              + Add Job{" "}
              </Button>
            </Col>
            {user_info.role_id == 13 ? 
            <Col xl="2">
              <Button
                className="col-12 dash-button "
                color="primary"
                type="button"
                onClick={amendmentModal}
              >
                Amendement
              </Button>
            </Col>
            : null }
            <Col xl="3">
              <Button
                className="dash-button "
                color="primary"
                type="button"
                onClick={() => add_budget()}
              >
                {" "}
              + Fixed Budget{" "}
              </Button>
            </Col>
            
            {user_info.role_id != 13 ? 
            <Col xl="6" className="text-right">
              <input type="file" ref={inputRef} name="file" id="import_file" className="text-right" style={{width:"40%"}} onChange={onFileChange}  />
      
              <Button
                className="dash-button"
                color="default"
                type="button"
                disabled={formloader}
                onClick={onRespondClick.bind(this)}
              >
                Import Bulk jobs {formloader ? (<Formloader height={20} color='#ffffff'></Formloader>) : (<></>)}
              </Button>
              <a onClick={downloadformatfile} target="_blank" download ><i className="ni ni-cloud-download-95"></i> Format</a>
            </Col>
          : null }
          </Row>
          <br></br>
         
          {(user_info.role_id == 13) || (allclient==false) ? 
      <>
          <Row className="dash-analy">
          <Col xl="2" md="2" xs="12"  className="dash-col-analy-3 dashtabs" >
           
                <Row className={(filtertype=='Not Started' ? "align-items-center dash-inner-row active" : "align-items-center dash-inner-row")}>
                  <div className="col" onClick={() => {get_list(1,null,filterFields,'Not Started'),setfiltertype('Not Started')}}>
                    <h1 style={{ color: "#FF8C00" }}>
                      {dashboardStatistics.notstarted_tasks}
                    </h1>
                    <h2 className="mb-0" style={{ color: "#FF8C00" }}>
                      Not Started 
                    </h2>
                  </div>
                </Row>
             
          </Col>
          {/* <Col xl="2" md="2" xs="12"  className="dash-col-analy-3" >
           
           <Row className="align-items-center dash-inner-row">
             <div className="col" onClick={() => {get_list(1,null,filterFields,'ReadyToStart'),setfiltertype('ReadyToStart')}}>
               <h1 style={{ color: "#FEA732" }}>
                 {dashboardStatistics.readytostart_tasks}
               </h1>
               <h2 className="mb-0" style={{ color: "#FEA732" }}>
                Ready to start
               </h2>
             </div>
           </Row>
        
     </Col> */}
          <Col xl="2" md="2" xs="12"  className="dash-col-analy-3 dashtabs" >
           
                <Row className={(filtertype=='In Progress' ? "align-items-center dash-inner-row active" : "align-items-center dash-inner-row")}>
                  <div className="col" onClick={() => {get_list(1,null,filterFields,'In Progress'),setfiltertype('In Progress')}}>
                    <h1 style={{ color: "#0060B8" }}>
                      {dashboardStatistics.inprogress_tasks}
                    </h1>
                    <h2 className="mb-0" style={{ color: "#0060B8" }}>
                      In Progress 
                    </h2>
                  </div>
                </Row>
             
          </Col>
          <Col xl="2" md="2" xs="12"  className="dash-col-analy-3 dashtabs" >
           
                <Row className={(filtertype=='Waiting For Information' ? "align-items-center dash-inner-row active" : "align-items-center dash-inner-row")}>
                
                  <div className="col" onClick={() => {get_list(1,null,filterFields,'Waiting For Information'),setfiltertype('Waiting For Information')}} >
                    <h1 style={{ color: "#FF1717" }}>
                      {dashboardStatistics.onhold_tasks}
                    </h1>
                    <h2 className="mb-0" style={{ color: "#FF1717" }}>
                      Waiting For Information 
                    </h2>
                  </div>
                </Row>
             
          </Col>
          <Col xl="2" md="2" xs="12"  className="dash-col-analy-3 dashtabs" >
           
           <Row className={(filtertype=='Completed' ? "align-items-center dash-inner-row active" : "align-items-center dash-inner-row")}>
             <div className="col" onClick={() => {get_list(1,null,filterFields,'Completed'),setfiltertype('Completed')}}>
               <h1 style={{ color: "#0DAF84" }}>
                 {dashboardStatistics.completed_tasks}
               </h1>
               <h2 className="mb-0" style={{ color: "#0DAF84" }}>
                 Completed 
               </h2>
             </div>
           </Row>
        
     </Col>
          </Row>
          <Row className="mt-4">
          <button type="button" style={filtertype == 'assigned' ?
          { backgroundColor:'#05a8a8',color:'#fff',fontFamily:'inherit',margin: '7px 0px'} : {fontFamily:'inherit',margin: '7px 0px'}} onClick={() => filterallcom({type:'assigned'})} className=" btn btn-secondary text-center">
            Pending
          </button>
          {/* <button type="button" style={filtertype == 'pending_approval' ?
          { backgroundColor:'#05a8a8',color:'#fff',fontFamily:'inherit',margin: '7px 0px'} : {fontFamily:'inherit',margin: '7px 0px'}} onClick={() => filterallcom({type:'pending_approval'})}  className=" btn btn-secondary text-center">
            Pending Approval
          </button>   */}
          <button type="button" style={filtertype == 'Completed' ?
          { backgroundColor:'#05a8a8',color:'#fff',fontFamily:'inherit',margin: '7px 0px'} : {fontFamily:'inherit',margin: '7px 0px'}} onClick={() => filterallcom({type:'Completed'})} className=" btn btn-secondary text-center">
            Completed
           
          </button>
        </Row>
        </>
          : null }
          
        <Row className="mt-5">
          <div className="col" style={{padding:'0%'}}>
            <Card className="shadow">
              <CardHeader className="border-0">
              <Row className="filter" style={{padding:'0 1%'}}>
               <div className="filter">
                    <h3 className="mb-0" style={{fontSize:'20px'}}> Task List &nbsp;&nbsp;</h3><br></br>
                    
                    <div>
                    {/* {(uniquecompanieslist.length > 0 && (user_info.role_id != 13))?
                          <select className="form-control" name="user_id" onChange={(e) => companyfilter(e,9)}>
                              <option value="" key="al_key_1">All Clients</option>
                              {(uniquecompanieslist).map((valuel,k) =>
                                  <option key={k} value={valuel.id}>
                                    {valuel.name}
                                  </option>
                              )}
                          </select>
                        : null } */}
                        {(uniquecompanieslist.length > 0 && user_info.role_id != 13) ? 
                   <Select 
                   
                   className="input-group-alternative " 
                   name="user_id"
                   options={uniquecompanieslist} // set list of the data
                   onChange={(e) => companyfilter(e,9,'user_id')}
                  //  value={usersearch}
                   searchable
                   isClearable={false}
                 />
                 : null }
                    </div>&nbsp;&nbsp;&nbsp;&nbsp;
                    {/* {(filteractive == true) ?
                    <div   style={{maxWidth:'50%',"margin-left":"10px"}}>
                    <Button
                      className="btn-icon-clipboard"
                      type="button"
                      onClick={downloadReport}
                      style={{textAlign:'center'}}
                      title="Download Report"
                    >
                    <i className="fas fa-download" style={{fontSize:'14px'}}></i> Export
                    </Button>
                    
                    </div>
                     :''} */}
                     {(user_info.role_id == 12 || user_info.role_id == 14) ?
                     <div>
                     <Button
                      className="btn-icon-clipboard"
                      type="button"
                      onClick={openModal}
                      style={{textAlign:'center'}}
                      
                    >MIS Log</Button>
                    </div>
                    : null }
                    </div>
                    {(filteractive == true || user_info.role_id == 13) ?
                    <div>
                      <button onClick={(e) => getFilterList()}  type="button" className="btn-icon-clipboard" data-clipboard-text="bold-down" title="Copy to clipboard" style={{padding: 10}}>
                        <div>
                          <img alt="..." src={require("../../assets/img/brand/filter.png")} style={{height:15, width:15}}/>
                          <span style={{"fontSize":16,fontFamily:"inter"}}>Filters</span>&nbsp;
                          {(showfilterbox) ? (<i className="ni ni-bold-up"></i>) : 
                            (<i className="ni ni-bold-down"></i>)}
                        </div>
                      </button>
                    </div>
                    :''}
                    
                   
                  
                </Row>
                {user_info.role_id != 13 ? 
                <>
                <br></br>
                <Row>
          <Col xs={4}>
                      <FormGroup>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <ReactDatetime
                            inputProps={{
                              placeholder: "Start date"
                            }}
                            timeFormat={false}
                            renderDay={(props, currentDate, selectedDate) => {
                              let classes = props.className;
                              if (
                                state.startDate &&
                                state.endDate &&
                                state.startDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " start-date";
                              } else if (
                                state.startDate &&
                                state.endDate &&
                                new Date(state.startDate._d + "") <
                                  new Date(currentDate._d + "") &&
                                new Date(state.endDate._d + "") >
                                  new Date(currentDate._d + "")
                              ) {
                                classes += " middle-date";
                              } else if (
                                state.endDate &&
                                state.endDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " end-date";
                              }
                              return (
                                <td {...props} className={classes}>
                                  {currentDate.date()}
                                </td>
                              );
                            }}
                            onChange={e => setStartDateRange(e)}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col xs={4}>
                      <FormGroup>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <ReactDatetime
                            inputProps={{
                              placeholder: "End date"
                            }}
                            timeFormat={false}
                            renderDay={(props, currentDate, selectedDate) => {
                              let classes = props.className;
                              if (
                                state.startDate &&
                                state.endDate &&
                                state.startDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " start-date";
                              } else if (
                                state.startDate &&
                                state.endDate &&
                                new Date(state.startDate._d + "") <
                                  new Date(currentDate._d + "") &&
                                new Date(state.endDate._d + "") >
                                  new Date(currentDate._d + "")
                              ) {
                                classes += " middle-date";
                              } else if (
                                state.endDate &&
                                state.endDate._d + "" === currentDate._d + ""
                              ) {
                                classes += " end-date";
                              }
                              return (
                                <td {...props} className={classes}>
                                  {currentDate.date()}
                                </td>
                              );
                            }}
                            onChange={e => setEndDateRange(e)}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
          </Row>
          </>
          : null }
                {(showfilterbox== true)?
                <Row style={{paddingLeft:'1%',textAlign:'right'}}>
                  <Col xl="12">
                   {(filterApplyFields) ?
                    (Object.values(filterApplyFields)).map((t,k) => (
                        <button key={k} className="btn btn-icon btn-3 btn-primary filter-button" type="button" style={{color:'#3F3F3F'}}>
                          <span className="btn-inner--text"><b>{t.field} #</b> {t.op_name} {(t.val_name)?t.val_name:t.value}</span>
                          <span className="btn-inner--icon" onClick={() => removefromFilter(t.field)}><i className="ni ni-fat-remove"></i></span>
                        </button>
                      )):null} 
                    <button style={{color:'#3F3F3F'}} type="button" className="btn btn-icon btn-primary filter-button" data-placement="right" id="tooltipfilter" >
                        +
                    </button>
                    {(filterlist.length > 0) ?
                    (<UncontrolledPopover placement="right" target="tooltipfilter">
                      <PopoverBody>
                      {filterlist.map((filter, i) => (
                      <React.Fragment key={i}>
                      <p key="para" data-placement="right" id={`tooltipraised-${i}`} style={{cursor:"pointer"}} name="field" value={filter.field} onClick={(e) => {filterClickHandler('field',filter.field,i),setfiltertype('')}}>{filter.field}</p>
                      {/* {(filter.values.length > 0)? */}
                      <UncontrolledPopover key={i} placement="right"  target={`tooltipraised-${i}`}>
                      <PopoverBody>
                         <Form>
                         {(filter.operators).map((operator,j) => (
                         <div key={j} className="custom-control custom-radio mb-3" style={{paddingLeft:'1rem'}}>
                          <input id="customRadio5"  value={operator.symbol} name="operator" type="radio" onChange={(e) => filterInputHandler(e,i,operator.value)} />
                          {/* <label className="custom-control-label" htmlFor="customRadio5"> */}
                           &nbsp;&nbsp; {operator.value}
                          {/* </label> */}
                        </div>
                         )) }
                          <div id="textboxes" >
                        {(filter.values.length > 0)?
                          <select className="form-control" name="value" onChange={(e) => filterInputHandler(e,i)}>
                              <option value="" key="al_key_1">Select values</option>
                              {(filter.values).map((valuel,k) =>
                                  <option key={k} value={valuel.id}>
                                    {valuel.name}
                                  </option>
                              )}
                          </select>
                        :
                        <input className="form-control" type={filter.type} name="value" onChange={(e) => filterInputHandler(e,i)} />
                        }
                        <br></br>
                        <button type="button" className="col-8 dash-button btn btn-primary" onClick={(e) => applyFilter()} > Apply </button>
                        {/* <button type="button" className="col-6 phase-button  btn btn-light">Cancel</button> */}
                         </div>
                         </Form>
                      </PopoverBody>
                    </UncontrolledPopover>
                       {/* :null }  */}
                     </React.Fragment>
                      ))}
                        </PopoverBody>
                    </UncontrolledPopover>):null }   
                  
                  </Col>  
                </Row> : null }
              </CardHeader>
              {(allclient == false || user_info.role_id == 13) ?
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                    {user_info.role_id != 13 ? <th>Client</th> : null }
                    <th scope="col"  className="whitespace sortclass" onClick={(e) => sortTable('sub_client')}>Sub Client   
                   <div className="sortarrow"><div class="up-arrow" ></div>
                    <div class="down-arrow"></div></div>
                    </th>
                    {/* <th scope="col"><i className="ni ni-chat-round"></i></th> */}
                    <th scope="col" className="whitespace sortclass" onClick={(e) => sortTable('type')}> Type <div className="sortarrow"><div class="up-arrow" ></div>
                    <div class="down-arrow"></div></div></th>
                    <th scope="col" className="whitespace sortclass" onClick={(e) => sortTable('year_end_date')}>YE Date  <div className="sortarrow"><div class="up-arrow" ></div>
                    <div class="down-arrow"></div></div></th>
                    <th scope="col" className="whitespace sortclass" onClick={(e) => sortTable('book_keeping_software')}>Book-keeping software<div className="sortarrow"><div class="up-arrow" ></div>
                    <div class="down-arrow"></div></div></th>
                    <th scope="col" className="whitespace sortclass" onClick={(e) => sortTable('priority_no')}>Priority <div className="sortarrow"><div class="up-arrow" ></div>
                    <div class="down-arrow"></div></div></th>
                    {/* <th scope="col">Raised By</th> */}
                    <th scope="col">Raised on</th>
                    {/* <th scope="col">Raised By</th> */}
                    <th scope="col" className="whitespace">Client Manager</th>
                    <th scope="col" className="whitespace sortclass" onClick={(e) => sortTable('last_doc_update_date')}>Date of Information last uploaded <div className="sortarrow"><div class="up-arrow" ></div>
                    <div class="down-arrow"></div></div></th>
                    <th scope="col" className="whitespace sortclass" onClick={(e) => sortTable('due_date')}>Expected Date of Completion <div className="sortarrow"><div class="up-arrow" ></div>
                    <div class="down-arrow"></div></div></th>
                    <th scope="col" className="whitespace sortclass" onClick={(e) => sortTable('corient_expected_completed_date')}>Corient Expected Completion Date <div className="sortarrow"><div class="up-arrow" ></div>
                    <div class="down-arrow"></div></div></th>
                    <th scope="col" className="whitespace sortclass" onClick={(e) => sortTable('completed_date')}>Actual Date of Completion <div className="sortarrow"><div class="up-arrow" ></div>
                    <div class="down-arrow"></div></div></th>
                    <th scope="col" className="whitespace">Status of the Job</th>
                    <th scope="col" className="whitespace">Corient Remarks</th>
                    <th scope="col" className="whitespace">Client Remarks</th>
                </thead>
                <tbody>
                  {clientdata &&
                    clientdata.length &&
                    clientdata != null ? (
                      clientdata.map((item, i) => (
                      <>
                     <tr className={((i % 2 == 0)? 'blue-row':(i % 3 == 0)?'purple-row':'orange-row') }>
                     {user_info.role_id != 13 ?  <td onClick={() => taskDetail(item.id, i)} style={{cursor:'pointer'}}>{item.name}</td> : null }
                     <td onClick={() => editjob_nav(item.id)} style={{cursor:'pointer'}}>{item.sub_client}</td>
                        {/* <td>
                          <div className="icon-container" style={{"position": "relative",'width':'20px'}}>
                          <img alt="..." src={require("../../assets/img/brand/chat-bubble.png")} style={{height:20, width:20}} id={item.id} onClick={(e) => addcommentform(item.id,i)}/>
                          {item.comment_count != null && item.comment_count > 0 ? (
                            <Badge className="badge-circle" color="danger" size="sm" style={{"position": "absolute","right": "-8px","bottom": "-7px","height":"1.0rem","width":"1.0rem","fontSize":"10px","color": "#fff","backgroundColor": "red"}}>
                              {item.comment_count}
                            </Badge>
                          ) : null}
                            </div>
                          </td> */}
                          
                          <td className="whitespace">{item.type}</td>
                          <td>{formatDate(item.year_end_date)}</td>
                        <td className="whitespace">{item.book_keeping_software}</td>
                        <td>{item.priority_no}</td>
                        {/* <td className="whitespace">{item.raised_by}</td> */}
                        <td>{formatDate(item.raised_date)}</td>
                        {/* <td className="whitespace">{item.raised_by}</td> */}
                      <td className="whitespace">{item.client_manager}</td>
                        <td>{item.doc_update_date}</td>
                        <td>{formatDate(item.due_date)}</td>
                        {user_info.role_id != 13 ?
                        <td  onClick={()=> handlemaineditRow(i)} >
                              {item.mainediting ? (
                                <input type="date"
                                className="form-control"
                                  defaultValue={item.corient_expected_completed_date}
                                
                                  onBlur={(e) =>
                                    editField(
                                      e.target.value,
                                      "corient_expected_completed_date",
                                      item.id,
                                      'tasks',
                                      i
                                    )
                                  }
                                  min={disablePastDate()}
                                />
                              ) : (
                                <span>{(item.corient_expected_completed_date!='') ? formatDate(item.corient_expected_completed_date) : ''}</span>
                              )}
                            </td>
                        : <td>{(item.corient_expected_completed_date!='') ? formatDate(item.corient_expected_completed_date) : ''}</td> }

                      {user_info.role_id != 13 ?
                        <td  onClick={()=> handlemaineditRow(i)} >
                              {item.mainediting ? (
                                <input type="date"
                                className="form-control"
                                  defaultValue={item.com_date}
                                
                                  onBlur={(e) =>
                                    editField(
                                      e.target.value,
                                      "com_date",
                                      item.id,
                                      'tasks',
                                      i
                                    )
                                  }
                                />
                              ) : (
                                <span>{(item.com_date!='') ? formatDate(item.com_date) : ''}</span>
                              )}
                            </td>
                        : <td>{(item.com_date!='') ? formatDate(item.com_date) : ''}</td> }

                        {user_info.role_id != 13 ?
                        <td>
                            {item.mainediting ? (
                            <select
                              style={{background: (item.status_color_code!=null)?`${item.status_color_code}`:'none',color:(item.status_color_code!=null)?'#fff':'#000'}}
                                className="form-control"
                                onChange={(e) =>
                                  editField(
                                    e.target.value,
                                    "client_status",
                                    item.id,
                                    'tasks',
                                    i
                                  )
                                }
                                defaultValue={item.status_id}
                              >
                                <option value="0" key="0" style={{background:'#fff',color:'#000'}}>Select Status</option>
                                {statuslist &&
                                statuslist.length &&
                                statuslist != null ? (
                                  statuslist.map((status) =>
                                  
                                      <option key={status.id} value={status.id} style={{background:`${status.color_code}`,color:'#fff'}}>
                                        {status.name}
                                      </option>
                                  )
                                ) : (
                                  <option>No data Found</option>
                                )}
                              </select> ) : (
                                <p onClick={()=> handlemaineditRow(i)}>{item.status_name}</p>
                              )}
                          </td>
                          :
                        <td>{item.status_name}</td> }
                        {user_info.role_id != 13 ? 
                        <td  onClick={()=> handlemaineditRow(i)} >
                        {item.mainediting ? (
                          <input type="text"
                          className="form-control"
                          defaultValue={item.remarks}
                          
                            onBlur={(e) =>
                              editField(
                                e.target.value,
                                "remarks",
                                item.id,
                                'tasks',
                                i
                              )
                            }
                          />
                        ) : (
                          <span>{item.remarks}</span>
                        )}
                      </td>
                        :
                        <td className="whitespace">{item.remarks}</td> }
                        {user_info.role_id == 13 ? 
                        <td  onClick={()=> handlemaineditRow(i)} >
                        {item.mainediting ? (
                          <input type="text"
                          className="form-control"
                          defaultValue={item.client_remarks}
                          
                            onBlur={(e) =>
                              editField(
                                e.target.value,
                                "client_remarks",
                                item.id,
                                'tasks',
                                i
                              )
                            }
                          />
                        ) : (
                          <span>{item.client_remarks}</span>
                        )}
                      </td>
                        :
                        <td className="whitespace">{item.client_remarks}</td> }
                     </tr>
                      
                      </>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={3}>No Data Found!</td>
                    </tr>
                  )}
                </tbody>
              </Table>
: 
<Table className="align-items-center table-flush custom-style" responsive>
                      <thead className="thead-light">
                        <tr>
                          <th>Client</th>
                          <th>In progress</th>
                          <th>Completed</th>
                          <th>Not Started</th>
                          <th>On Hold</th>
                          <th>Hours</th>
                          <th>Send Mail</th>
                        </tr>
                      </thead>
                  <tbody>
                  {clientdata &&
                    clientdata.length &&
                    clientdata != null ? (
                      clientdata.map((user, index) => (
                         <tr style={{backgroundColor: (index %2 ==0) ? '#F4F4F4' : '#FFFFFF'}}>
                            <td>{user.name}</td>
                            <td><span class="inprogress"  style={{cursor:'pointer'}} onClick={() => { report_details(user.id,user.role_id,'Inprogress') }}>{user.Inprogress != undefined ? user.Inprogress : 0}</span></td>
                            <td><span class="complete" style={{cursor:'pointer'}} onClick={() => { report_details(user.id,user.role_id,'Complete') }}>{user.Complete != undefined ? user.Complete : 0}</span></td>
                            <td><span class="notstarted" style={{cursor:'pointer'}} onClick={() => { report_details(user.id,user.role_id,'NotStarted') }}>{user.NotStarted != undefined ? user.NotStarted : 0}</span></td>
                            <td><span class="notstarted" style={{cursor:'pointer'}} onClick={() => { report_details(user.id,user.role_id,'Onhold') }}>{user.Onhold != undefined ? user.Onhold : 0}</span></td>
                            <td>{user.Hours}</td>
                            <td><button type="button" style={{width:'auto'}}  onClick={() => getpopupdata(user.id)} title="Send Mail" class="btn-icon-clipboard btn btn-secondary " >
              <i class="fa fa-envelope" style={{fontSize:"14px"}}></i> 
            </button></td>
                         </tr>
                    ))
                    ):'No records found' }
                    </tbody>
                  </Table>
} 
{(allclient == false || (user_info.role_id == 13))?
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem disabled={currentPage <= 1}>
                      <PaginationLink onClick={handlePreviousClick} previous />
                    </PaginationItem>
                    {[...Array(last_page)].map((page, i) => (
                      <PaginationItem
                        active={i + 1 === currentPage}
                        key={i + 1}
                      >
                        <PaginationLink
                          onClick={(e) => handlePageClick(e, i + 1)}
                        >
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem disabled={currentPage >= last_page}>
                      <PaginationLink onClick={handleNextClick} next />
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
: null }
            </Card>
          </div>
        </Row>
        
        <Row>
          <Modal
            size="lg"
            className="taskdetailmodal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            toggle={() => settaskdetailPopup(!taskdetailPopup)}
            isOpen={taskdetailPopup}
          >
            <div className="modal-header">
            <h3>Task Detail</h3>
              <button
                aria-label="Close"
                className=" close"
                type="button"
                onClick={() => settaskdetailPopup(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody style={{paddingTop:'0px'}}>
            {documentlists.length > 0 ?
            <button type="button" onClick={() => setdocumentModalOpen(true) } title="Download Documents" class="btn-icon-clipboard btn btn-secondary col-md-3" style={{textAlign:"center"}}>
              <i class="fas fa-eye" style={{fontSize:"14px"}}></i> View Documents
            </button>
            : null }
            {rejectionlists.length > 0 ?
            <button type="button" onClick={() => setrejectionModalOpen(true) } title="View Rejections" class="btn-icon-clipboard btn btn-secondary col-md-3" style={{textAlign:"center"}}>
              <i class="fas fa-eye" style={{fontSize:"14px"}}></i> View Rejections
            </button>
            : null }
             {taskdetails.user_id != null ?
            <button type="button" onClick={() => setotherInfoModalOpen(true) } title="Other Info" class="btn-icon-clipboard btn btn-secondary col-md-3" style={{textAlign:"center"}}>
              <i class="fas fa-eye" style={{fontSize:"14px"}}></i> Other Info
            </button>
            : null }
          {(taskdetails.company)?  <p><b>Book Keeping Software :</b> {taskdetails.company.book_keeping_software} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           <b>Account Production Software</b> : {taskdetails.company.account_prod_software}</p>  :null }
           
              <Table className="align-items-center table-flush" responsive>
                <thead>
                  <tr>
                    <th>Type</th>
                    <th className="whitespace">Assigned Leader</th>
                    <th className="whitespace">Assigned Member</th>
                    <th>Due date</th>
                    <th>Start date</th>
                    <th>Comp. date</th>
                    <th className="whitespace">Status</th>
                    <th>Approval</th>
                  </tr>
                </thead>
                <tbody>
                {taskdetails.analyst  ? (
                  <tr key="analyst" className="blue-row">
                    <td>Analyst</td>
                    <td className="whitespace">{taskdetails.analyst.leader}</td>
                    <td className="whitespace">{taskdetails.analyst.member}</td>
                    <td >{taskdetails.analyst.due_date}</td>
                    <td>{taskdetails.analyst.start_date}</td>
                    <td>{taskdetails.analyst.completed_date}</td>
                    <td className="whitespace">{taskdetails.analyst.status ? taskdetails.analyst.status.name : ""}</td>
                    <td>{taskdetails.analyst.approval==1 ? 'Approved' : taskdetails.analyst.approval==2 ? 'Denied' : ''}
                    {taskdetails.analyst.flag==1 ? 'Ready for review' : taskdetails.analyst.flag==2 ? 'Phases assigned by QA' : taskdetails.analyst.flag==3 ? 'Rejected by QA' : taskdetails.analyst.flag==4 ? 'Reviewed' : ''}
                    </td>
                  </tr>
                 
                 ) : (
                  null
                )}

                      {taskdetails.phases && taskdetails.phases.length
                          ?                               
                          taskdetails.phases.map((phase,d) => (
                              <tr key={d} className={((d % 2 == 0)? 'purple-row': 'orange-row' )}>
                                <td>{phase.phases_list.desc}</td>
                                <td  className="whitespace">{phase.leader}</td>
                                <td  className="whitespace">{phase.member}</td>
                                <td>{phase.due_date}</td>
                                <td>{phase.start_date}</td>
                                <td>{phase.completed_date}</td>
                                <td  className="whitespace">{phase.status ? phase.status.name : ""}</td>
                                <td>{phase.approval==1 ? 'Approved' : phase.approval==2 ? 'Denied' : ''}
                                    {phase.flag==1 ? 'Rejected' : ''}
                                </td>
                              </tr>
                            ))
                          :
                           ( <p>No Data Found</p> )}
                </tbody>
              </Table>
            </ModalBody>
           </Modal>
         
            <Modal className="taskdetailmodal"  isOpen={documentModalOpen} scrollable={true}>
            <div className=" modal-header">
              <h5 className=" modal-title" id="exampleModalLabel">
              Documents 
              </h5>
              
              <button aria-label="Close" className=" close" type="button" onClick={() => setdocumentModalOpen(!documentModalOpen)}>
                <span aria-hidden={true}>×</span>
              </button>
              
            </div>
            
            <ModalBody>
            <button type="button" onClick={() => downloadAllfile(taskdetails.user_id,taskdetails.id)} title="Download All Documents" class="btn-icon-clipboard btn btn-secondary col-md-3" style={{textAlign:"center"}}>
              <i class="fas fa-download" style={{fontSize:"14px"}}></i> Download All Documents
            </button>
              <Table>
              <thead>
                  <tr>
                    <th className="whitespace">Document Checklist</th>
                    <th className="whitespace">Remarks</th>
                    <th className="whitespace">Document</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                {documentlists.length > 0 && documentlists  ? (
                        documentlists.map((document, i) => (
                  <React.Fragment key={i}>
                  <tr key={i}>
                    <td className="whitespace">{document.doc_type}</td>
                    <td className="whitespace">{document.remark}</td>
                    <td className="whitespace"><a onClick={() => downloadfile(document.doc_path,document.file_name,document.extension,taskdetails.id)} download>Download</a></td>
                    <td className="whitespace">{document.date}</td>
                  </tr>
                  </React.Fragment>
                  ))
                 ) : (
                  <tr>
                  <td>No Data Found</td>
                  </tr>
                )}
                </tbody>
              </Table>
            </ModalBody>
          </Modal>

          <Modal className="taskdetailmodal"  isOpen={rejectionModalOpen} scrollable={true}>
            <div className=" modal-header">
              <h5 className=" modal-title" id="exampleModalLabel">
              Rejections 
              </h5>
              <button aria-label="Close" className=" close" type="button" onClick={() => setrejectionModalOpen(!rejectionModalOpen)}>
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody>
              <Table>
              <thead>
                  <tr>
                    <th className="whitespace">Rejection for</th>
                    <th className="whitespace">Phase</th>
                    <th className="whitespace">Category</th>
                    <th className="whitespace">Comment</th>
                    <th className="whitespace">Status</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                {rejectionlists.length > 0 && rejectionlists  ? (
                        rejectionlists.map((rejection, i) => (
                  <React.Fragment key={i}>
                  <tr key={i} className={((i % 2 == 0)? 'blue-row':(i % 3 == 0)?'purple-row':'orange-row') }>
                    <td className="whitespace">{rejection.type}</td>
                    <td className="whitespace">{rejection.phase_name}</td>
                    <td className="whitespace">{rejection.cat_name}</td>
                    <td className="whitespace">{rejection.comment}</td>
                    <td className="whitespace" onClick={()=> handlerejeditRow(i)}>
                    {rejection.editing ? (
                    <select
                                className="form-control"
                                onChange={(e) =>
                                  editRejField(
                                    e.target.value,
                                    "status",
                                    rejection.id,
                                    i
                                  )
                                }
                                defaultValue={rejection.status}
                              >
                                <option value="0" key="0" >Select Status</option>
                                {statuslist &&
                                statuslist.length &&
                                statuslist != null ? (
                                  statuslist.map((status) =>
                                  
                                      <option key={status.id} value={status.id} >
                                        {status.name}
                                      </option>
                                  )
                                ) : (
                                  <option>No data Found</option>
                                )}
                              </select> ) : (
                                <p>{rejection.status_name}</p>
                              )} 
                              </td>
                  <td className="whitespace">{rejection.date}</td>
                  </tr>
                  </React.Fragment>
                  ))
                 ) : (
                  <tr>
                  <td>No Data Found</td>
                  </tr>
                )}
                </tbody>
              </Table>
            </ModalBody>
          </Modal>
          <Modal className="otherinfomodal"  isOpen={otherInfoModalOpen} scrollable={true}>
            <div className=" modal-header">
              <h5 className=" modal-title" id="exampleModalLabel">
              All Info 
              </h5>
              <button aria-label="Close" className=" close" type="button" onClick={() => setotherInfoModalOpen(!otherInfoModalOpen)}>
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody>
              <p> Client Manager : {taskdetails.client_manager}</p><br></br>
              <p> Whether VAT register : {(taskdetails.vat_register == 1) ? 'YES' : 'NO'}</p><br></br>
              <p> VAT Scheme : {taskdetails.vat_scheme}</p><br></br>
              <p> Point of contact : {taskdetails.point_of_contact}</p><br></br>
              <p> Whether Bookkeeping Done : {(taskdetails.bookkeeping_done == 1) ? 'YES' : 'NO'}</p><br></br>
              <p> Whether to be completed bookkeeping? : {(taskdetails.need_bookkeeping == 1) ? 'YES' : 'NO'}</p><br></br>
              <p> Whether Account Production Done : {(taskdetails.account_production_done == 1) ? 'YES' : 'NO'}</p><br></br>
              <p> Whether YE Journals to be accounted in Bkg Software? : {(taskdetails.ye_journals == 1) ? 'YES' : 'NO'}</p><br></br>
              <p> Access Details : {taskdetails.access_details}</p><br></br>
              <p> Priority No. : {taskdetails.priority_no}</p><br></br>
              <p> Plan : {taskdetails.plan}</p><br></br>
              <p> Last document updated date : {taskdetails.last_doc_update_date}</p><br></br>
            </ModalBody>
          </Modal>

          <Modal
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            toggle={() => amendmentModal()}
            isOpen={amendmentModalOpen}
          >
            <div className="modal-header">
              <h5 className=" modal-title" id="exampleModalLabel">
                Amendment
              </h5>
              <button
                aria-label="Close"
                className=" close"
                type="button"
                onClick={() => amendmentModal()}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody>
              <>
                <Row className="d-flex justify-content-center">
                  <label className="col-12">Select sub client</label>
                  <div className="col-12">
                  <Select 
                            className="input-group-alternative mb-3" 
                            name="am_company" 
                            options={companylist} // set list of the data
                            onChange={(e) => amenmentdatadata(e,'am_company')}
                            value={{label: amendmentdata.am_company_name,value: amendmentdata.am_company}}
                            searchable
                            isClearable={false}
                          />
                    {/* <select
                      className="input-group-alternative mb-3"
                      name="am_company"
                      onChange={amenmentdatadata}
                    > 
                      <option key="comp_key_1" value="">
                        Select company
                      </option>
                      {companylist.length > 0 && companylist != "" ? (
                        companylist.map((item, i) => (
                          <option
                            key={companylist[i].id}
                            value={companylist[i].id}
                          >
                            {companylist[i].name} ({companylist[i].sub_client})
                          </option>
                        ))
                      ) : (
                        <option key="comp_key_2" value="">
                          No data
                        </option>
                      )} */}

                      {/* <option>Xyz company</option>
                            <option>Test Xyz company</option> */}
                    {/* </select> */}
                  </div><br></br><br></br>
                  <label className="col-12">Add Budget</label>
                  <div className="col-12">
                    <Input
                        type="number"
                        placeholder="Budget"
                        name="am_budget"
                        onChange={amenmentdatadata}
                        inputmode="numeric"
                      />
                  </div><br></br><br></br>
                  <label className="col-12">Due date</label>
                  <div className="col-12">
                  <Input
                      type="date"
                      placeholder="Select due date"
                      defaultValue={amendmentdata.select_due_date}
                      name="select_due_date"
                      onChange={amenmentdatadata}
                      min={disablePastDate()}
                    />
                  </div>
                  <div className="mt-4 col-12">
                    {opensecondpopup ? (
                      <Button
                        className="btn btn-success"
                        onClick={apply_amendment}
                      >
                        Save
                      </Button>
                    ) : (
                      <Button className="btn btn-success" disabled={true}>
                        Save
                      </Button>
                    )}
                    <Button
                      className="ml-4"
                      type="button"
                      onClick={() => amendmentModal()}
                    >
                      Cancel
                    </Button>
                  </div>
                </Row>
                <br></br>
              </>
            </ModalBody>
            <ModalFooter></ModalFooter>
          </Modal>
          <Modal
            size="lg"
            className="taskdetailmodal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            toggle={() => setsendmailPopup(!sendmailpopup)}
            isOpen={sendmailpopup}
          >
            <div className="modal-header">
            
              <button
                aria-label="Close"
                className=" close"
                type="button"
                onClick={() => setsendmailPopup(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody style={{paddingTop:'0px'}}>
              {sendmailpopupdata ? 
                <div>
    <p>Hi <input  defaultValue={sendmailpopupdata.name} name="name" type="text" onChange={(e) => changebody(e)} />,</p>
    <p>Hope you are doing well!</p>
    <p>Please find the below status of jobs on our workflow:</p>
    {sendmailpopupdata.status_data && sendmailpopupdata.status_data.length ? (
        <table   style={{ verticalAlign: 'top', width: '60%', margin: 0, backgroundColor: '#f9f9f9' }} cellPadding="0" cellSpacing="0"  id="main_table">
            <thead style={customStyles['#main_table tr th']}>
                <tr>
                    <th style={{ width: '60%' }}>Particulars</th>
                    <th style={{ width: '20%' }}>No of Jobs</th>
                </tr>
            </thead>
            <tbody>
                <tr style={customStyles.oddRow}><td style={customStyles.cell}>Not Started</td><td style={customStyles.cell} className="noofjob">{sendmailpopupdata.status_data[0].NotStarted !== undefined ? sendmailpopupdata.status_data[0].NotStarted : '-'}</td></tr>
                {/* <tr style={customStyles.oddRow}><td style={customStyles.cell}>Pending for Missing Information</td><td style={customStyles.cell} className="noofjob">{sendmailpopupdata.status_data[0].Onhold !== undefined ? sendmailpopupdata.status_data[0].Onhold : '-'}</td></tr> */}
                <tr style={customStyles.oddRow}><td style={customStyles.cell}>WIP</td><td style={customStyles.cell} className="noofjob">{sendmailpopupdata.status_data[0].Inprogress !== undefined ? sendmailpopupdata.status_data[0].Inprogress : '-'}</td></tr>
                <tr style={customStyles.oddRow}><td style={customStyles.cell}>On Hold</td><td style={customStyles.cell} className="noofjob">{sendmailpopupdata.status_data[0].Onhold !== undefined ? sendmailpopupdata.status_data[0].Onhold : '-'}</td></tr>
                <tr style={customStyles.oddRow}><td style={customStyles.cell}>Checklist done</td><td style={customStyles.cell} className="noofjob">{sendmailpopupdata.status_data[0].Checklistdone !== undefined ? sendmailpopupdata.status_data[0].Checklistdone : '-'}</td></tr>
                <tr style={customStyles.oddRow}><td style={customStyles.cell}>Basic analysis started</td><td style={customStyles.cell} className="noofjob">{sendmailpopupdata.status_data[0].Basicanalysisstarted !== undefined ? sendmailpopupdata.status_data[0].Basicanalysisstarted : '-'}</td></tr>
                <tr style={customStyles.oddRow}><td style={customStyles.cell}>Under review</td><td style={customStyles.cell} className="noofjob">{sendmailpopupdata.status_data[0].UnderReview !== undefined ? sendmailpopupdata.status_data[0].UnderReview : '-'}</td></tr>
                <tr style={customStyles.oddRow}><td style={customStyles.cell}>Completed</td><td style={customStyles.cell} className="noofjob">{sendmailpopupdata.status_data[0].Complete !== undefined ? sendmailpopupdata.status_data[0].Complete : '-'}</td></tr>
            </tbody>
        </table>
    ) : null}
    <br />
    {sendmailpopupdata.data2 ? (
      <>
        <table style={{ verticalAlign: 'top', width: '100%', margin: 0, backgroundColor: '#f9f9f9' }} cellPadding="0" cellSpacing="0"  id="main_table">
            <thead style={customStyles['#main_table tr th']} >
                <tr>
                    <th>Client Name</th>
                    <th>Year-End</th>
                    <th>Job received date</th>
                    <th>Expected Completion date</th>
                    <th>Completion date</th>
                    <th>Status</th>
                    <th>Budget</th>
                    <th>Remarks</th>
                </tr>
            </thead>
            <tbody>
                {sendmailpopupdata.data2.map((item, i) => (
                    <tr key={i} style={customStyles.oddRow}>

                        <td style={customStyles.cell}>{item.sub_client}</td>
                        <td style={customStyles.cell}>{item.year_end_date && item.year_end_date != '0000-00-00' ? new Date(item.year_end_date).toLocaleDateString('en-GB') : ''}</td>
                        <td style={customStyles.cell}>{item.raised_date && item.raised_date != '0000-00-00' ? new Date(item.raised_date).toLocaleDateString('en-GB') : ''}</td>
                        <td style={customStyles.cell}>{item.corient_expected_completed_date && item.corient_expected_completed_date != '0000-00-00' ? new Date(item.corient_expected_completed_date).toLocaleDateString('en-GB') : ''}</td>
                        <td style={customStyles.cell}>{item.completion}</td>
                        <td style={customStyles.cell}>{item.status_name}</td>
                        {/* <td style={customStyles.cell}>{item.budget}</td> */}
                        <td style={customStyles.cell} onClick={()=> handleeditRow(i)}>
                  {item.editing ? (
                    <Input type="number" placeholder="impact" name ="financial_impact"   defaultValue={item.budget}
                    onBlur={(e) =>
                   inputhandler(
                     e,
                     "budget",
                     i,item.id
                   )} />
                  ) : <span>{item.budget}</span> }
              </td>
                        <td style={customStyles.cell}>{item.remarks}</td>
                    </tr>
                ))}
                {sendmailpopupdata.total_budet >= 0 &&
                <tr>
                    <td colSpan="6" style={customStyles.cell}></td>
                    <td style={customStyles.cell}>{sendmailpopupdata.total_budet}</td>
                    <td style={customStyles.cell} colSpan="1"></td>
                </tr>
                }
            </tbody>
        </table>
        <br></br>
        <p>
        <textarea
        name="emailbody"
      className="form-control no-scroll" // Combine classes
      defaultValue={sendmailpopupdata.body}
      rows={3}
      cols={50}
      style={noscroll}
      onChange={(e) => changebody(e)}
    />
          </p>
          
          <input type="file"  name="mail_attachemnt" id="import_file" className="text-right" style={{width:"40%"}} onChange={onFileChange1} multiple  />
          <br></br>
          <div className="mt-2">
  <Label>Add To Mail Id</Label>

  <div className="d-flex flex-wrap"> {/* Flex container for horizontal layout */}
    {(() => {
      const arr1 = [];
      for (let k = 0; k < tobox; k++) {
        arr1.push(
          <div className="d-flex align-items-center " key={k}> {/* Flex item */}
            <div className=""> {/* Adjust column size as needed */}
              <FormGroup>
                <InputGroup className="input-group-alternative" style={{border:"1px solid grey"}}>
                  <Input
                    placeholder="Name"
                    type="text"
                    name="tomail"
                    defaultValue={sendmailpopupdata.tomail[k] ? sendmailpopupdata.tomail[k] : ''}
                    onChange={(e) => inputTomailhandler(e, k)}
                    required
                  />
                </InputGroup>
              </FormGroup>
            </div>

            <Col md="1" className="d-flex justify-content-center" style={{ paddingTop: '0px' }}>
              {sendmailpopupdata.tomail.length > 1 && (
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => removeCCbox(k)}
                >
                  <i className="fa fa-remove"></i>
                </button>
              )}
            </Col>
          </div>
        );
      }
      return arr1;
    })()}
     
  </div>

  <br />
 
          </div>
          <div className="mt-2">
  <Label>Add CC Mail Id</Label>

  <div className="d-flex flex-wrap"> {/* Flex container for horizontal layout */}
    {sendmailbodydata.emailccboxData.map((email, k) => ( // Use map to iterate through emailccboxData
      <div className="d-flex align-items-center mb-2" key={k}> {/* Flex item */}
        <div className="col"> {/* Adjust column size as needed */}
          <FormGroup>
            <InputGroup className="input-group-alternative" style={{ border: "1px solid grey" }}>
              <Input
                placeholder="Name"
                type="text"
                name="ccmail"
                value={email} // Controlled input
                onChange={(e) => inputCCmailhandler(e, k)}
                required
              />
            </InputGroup>
          </FormGroup>
        </div>

        <Col md="1" className="d-flex justify-content-center" style={{ paddingTop: '0' }}>
         
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => removeEmailbox(k)} // Pass the correct index
            >
              <i className="fa fa-remove"></i>
            </button>
          
        </Col>
      </div>
    ))}
  </div>

  <FormGroup style={{ paddingTop: '3px' }}>
    <button className="btn btn-info" onClick={addCCbox}>+ </button>
  </FormGroup>
  <br />
</div>


       <br></br>
       
        </>
        
    ) : null}
      {/* <FormGroup style={{paddingTop:'3px'}}>
      <a className="btn btn-info"  onClick={(e) => addTobox()}>+ </a></FormGroup> */}
</div>

            :' '}
             <button type="button" className="col-2 dash-button btn btn-primary mt-4" onClick={(e) => sendmail(1)}  disabled={disablestate}>{disablestate ? 'Sending...' : 'Send Mail'}  </button>
            </ModalBody>
            
           </Modal>
           <Modal
            size="lg"
            className="taskdetailmodal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            isOpen={sendmailpopupdetails}
      toggle={() => setsendmailPopupDetails(!sendmailpopupdetails)}
          >
            <div className="modal-header">
            <h3>Mis Mailsent Log</h3>
              <button
                aria-label="Close"
                className=" close"
                type="button"
                onClick={() => setsendmailPopupDetails(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody style={{paddingTop:'0px'}}>
             
        <table style={{ verticalAlign: 'top', width: '100%', margin: 0, backgroundColor: '#f9f9f9' }} cellPadding="0" cellSpacing="0"  id="main_table">
            <thead style={customStyles['#main_table tr th']} >
                <tr>
                <th style={{ width: '25%' }}>Mail To</th>
                <th style={{ width: '25%' }}>Mail From</th>
                <th style={{ width: '25%' }}>Mail CC</th>
                <th style={{ width: '25%' }}>Body</th>
                </tr>
            </thead>
            <tbody>
                {misMailData && misMailData.length > 0 ? 
                  misMailData.map((item, i) => (
                    <tr key={i} style={customStyles.oddRow}>

<td style={customStyles.cell} className="noofjob">{item.mailto}</td>
                  <td style={customStyles.cell} className="noofjob">{item.mailfrom}</td>
                  <td className="noofjob" style={{
                ...customStyles.cell, // Spread operator to include custom styles
                width: '100px',       // Additional inline styles
                wordWrap: 'break-word',
                wordBreak: 'break-word'
              }}>({item.mailcc})</td>
                  <td style={customStyles.cell} className="noofjob">{item.data || 'N/A'}</td>
                    </tr>
                )) : <tr><td>No</td></tr>}
                
            </tbody>
        </table>
       
       
        
     


           
            </ModalBody>
            
           </Modal>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  subclientname: state.subclientsearch.subclientname,
});
export default connect(mapStateToProps)(Client);

